<template>
  <div class="h-full flex flex-col min-h-0">
    <div class="shrink-0 mb-4 flex justify-between items-center">
      <h3 class="text-2xl font-bold">Weersverwachting</h3>
    </div>

    <div v-if="loading" class="h-full flex items-center justify-center">
      <p class="text-2xl text-gray-400">Loading weather data...</p>
    </div>

    <div v-else-if="error" class="h-full flex items-center justify-center">
      <p class="text-2xl text-red-400">{{ error }}</p>
    </div>

    <div v-else-if="weather" class="flex-1 grid grid-cols-[1fr_2fr] gap-8 min-h-0">
      <!-- Current Weather -->
      <div class="bg-primary/50 rounded-xl p-6 flex flex-col min-h-0">
        <div class="flex flex-col items-center justify-center h-full space-y-4">
          <!-- Weather Icon -->
          <img
            :src="`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@4x.png`"
            :alt="weather.current.weather[0].description"
            class="w-28 h-28"
          />

          <!-- Temperature -->
          <div class="space-y-1 text-center">
            <p class="text-6xl font-bold">
              {{ Math.round(weather.current.temp) }}°C
            </p>
            <p class="text-xl text-gray-300 capitalize">
              {{ weather.current.weather[0].description }}
            </p>
          </div>

          <!-- Additional Info -->
          <div class="grid grid-cols-2 gap-4 text-lg w-full">
            <div>
              <p class="text-gray-400">Vochtigheid</p>
              <p class="font-semibold">{{ weather.current.humidity }}%</p>
            </div>
            <div>
              <p class="text-gray-400">Wind</p>
              <p class="font-semibold">{{ Math.round(weather.current.wind_speed * 3.6) }} km/h</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Forecast -->
      <div class="bg-primary/50 rounded-xl p-6 min-h-0">
        <div class="grid grid-cols-4 h-full gap-4">
          <div
            v-for="day in nextFourDays"
            :key="day.dt"
            class="flex flex-col items-center justify-center gap-2"
          >
            <!-- Day -->
            <p class="text-lg text-gray-300">
              {{ formatDay(day.dt) }}
            </p>

            <!-- Weather Icon -->
            <img
              :src="`https://openweathermap.org/img/wn/${day.weather[0].icon}@2x.png`"
              :alt="day.weather[0].description"
              class="w-14 h-14"
            />

            <!-- Temperature -->
            <div class="text-center">
              <p class="text-2xl font-bold">{{ Math.round(day.temp.max) }}°</p>
              <p class="text-lg text-gray-400">{{ Math.round(day.temp.min) }}°</p>
            </div>

            <!-- Precipitation Chance -->
            <div class="h-6 flex items-center justify-center">
              <p v-if="day.pop > 0" class="text-sm text-blue-400">
                {{ Math.round(day.pop * 100) }}% kans
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue'
import dayjs from '../plugins/dayjs.js'
import {getWeather} from "../services/weatherService.js"

const weather = ref(null)
const loading = ref(true)
const error = ref(null)
const location = ref('Ermelo, NL')

const nextFourDays = computed(() => {
  if (!weather.value) return []
  return weather.value.daily.slice(1, 5)
})

const formatDay = (timestamp) => {
  return dayjs(timestamp * 1000).format('dddd')
}

async function fetchWeather() {
  try {
    loading.value = true
    error.value = null
    weather.value = await getWeather()
  } catch (err) {
    error.value = 'Kon het weer niet laden'
    console.error('Weather error:', err)
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await fetchWeather()
})
</script>
