import axios from 'axios'

const API_KEY = '2247e89f332162e4f69cb1e6df9247e0'
const BASE_URL = 'https://api.openweathermap.org/data/3.0'
const CACHE_DURATION = 30 * 60 * 1000 // 30 minutes in milliseconds

// Cache structure
let weatherCache = {
  data: null,
  timestamp: null
}

export const getWeather = async (lat = 51.3323, lon = 3.8324) => {
  try {
    // Check if we have valid cached data
    if (weatherCache.data && weatherCache.timestamp) {
      const now = Date.now()
      const cacheAge = now - weatherCache.timestamp

      // If cache is still valid, return cached data
      if (cacheAge < CACHE_DURATION) {
        console.log('Returning cached weather data')
        return weatherCache.data
      }
    }

    // If no valid cache, fetch new data
    console.log('Fetching fresh weather data')
    const response = await axios.get(`${BASE_URL}/onecall`, {
      params: {
        lat,
        lon,
        appid: API_KEY,
        units: 'metric',
        exclude: 'minutely',
        lang: 'nl'
      }
    })

    // Update cache
    weatherCache = {
      data: response.data,
      timestamp: Date.now()
    }

    return response.data
  } catch (error) {
    console.error('Error fetching weather:', error)

    // If there's an error but we have cached data, return it as fallback
    if (weatherCache.data) {
      console.log('Returning cached data as fallback due to API error')
      return weatherCache.data
    }

    throw error
  }
}

// Helper function to clear cache if needed
export const clearWeatherCache = () => {
  weatherCache = {
    data: null,
    timestamp: null
  }
}

// Helper function to get cache status
export const getWeatherCacheStatus = () => {
  if (!weatherCache.timestamp) {
    return { isCached: false, age: null }
  }

  const age = Date.now() - weatherCache.timestamp
  return {
    isCached: true,
    age,
    isValid: age < CACHE_DURATION,
    expiresIn: CACHE_DURATION - age
  }
}
