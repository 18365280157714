<template>
  <section class="h-screen w-screen">
    <!-- Loading State -->
    <div v-if="isInitialLoading" class="h-full w-full flex items-center justify-center">
      <p class="text-3xl text-gray-400">Loading dashboard...</p>
    </div>

    <!-- Main Content - Add max-height constraint -->
    <div v-else class="h-full w-full p-6 max-h-screen">
      <div class="h-full w-full max-h-[calc(100vh-3rem)]"> <!-- Account for padding -->
        <!-- Construction Camera View -->
        <div v-if="showLatestImage && streamAvailable" class="h-full w-full">
          <div class="bg-secondary rounded-xl shadow-xl h-full">
            <latest-image  v-if="showLatestImage"
                           @stream-error="handleStreamStatus(false)"
                           @stream-ready="handleStreamStatus(true)" />
          </div>
        </div>

        <!-- Dashboard View -->
        <div v-else class="h-full w-full">
          <Transition name="fade" mode="out-in">
            <div v-if="showExpandedNews" class="h-full w-full grid grid-rows-[1fr_1fr] gap-6">
              <!-- Top Row: Time and Headlines -->
              <div class="h-full w-full min-h-0 grid grid-cols-[1fr_2fr] gap-6">
                <!-- Time Card -->
                <div class="bg-secondary rounded-xl shadow-xl flex items-center justify-center min-h-0">
                  <div class="text-center p-4">
                    <p class="text-8xl font-bold mb-4 tracking-tight">{{ formattedTime }}</p>
                    <h2 class="text-4xl capitalize">{{ formattedDate }}</h2>
                  </div>
                </div>

                <!-- Compact Headlines View -->
                <div class="bg-secondary rounded-xl shadow-xl min-h-0">
                  <div class="h-full overflow-hidden">
                    <HeadlinesView :news-posts="newsPosts" />
                  </div>
                </div>
              </div>

              <!-- Bottom Row: Full Weather -->
              <div class="bg-secondary rounded-xl shadow-xl min-h-0">
                <div class="h-full p-8 overflow-hidden">
                  <WeatherWidget />
                </div>
              </div>
            </div>
            <div v-else class="h-full w-full grid grid-cols-[1fr_2fr] gap-6">
              <!-- Left Column: Time and Compact Weather -->
              <div class="grid grid-rows-[1fr_1fr] gap-6 min-h-0">
                <!-- Time Card -->
                <div class="bg-secondary rounded-xl shadow-xl flex items-center justify-center min-h-0">
                  <div class="text-center p-4">
                    <p class="text-8xl font-bold mb-4 tracking-tight">{{ formattedTime }}</p>
                    <h2 class="text-4xl capitalize">{{ formattedDate }}</h2>
                  </div>
                </div>

                <!-- Compact Weather -->
                <div class="bg-secondary rounded-xl shadow-xl min-h-0">
                  <div class="h-full p-6 overflow-hidden">
                    <CompactWeatherWidget />
                  </div>
                </div>
              </div>

              <!-- Right Column: Full News -->
              <div class="bg-secondary rounded-xl shadow-xl min-h-0">
                <div class="h-full overflow-hidden">
                  <NewsCarousel
                    :news-posts="newsPosts"
                    :loading="newsLoading"
                    :error="newsError"
                    class="h-full"
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'
import dayjs from '@/plugins/dayjs'
import NewsService from '@/services/NewsService'
import LatestImage from "./LatestImage.vue"
import NewsCarousel from "./NewsCarousel.vue"
import WeatherWidget from "./WeatherWidget.vue"
import CompactWeatherWidget from "./CompactWeatherWidget.vue"
import HeadlinesView from "./HeadlinesView.vue"

const dateTime = ref(new Date())
const newsPosts = ref([])
const showLatestImage = ref(false)
const streamAvailable = ref(false)
const showExpandedNews = ref(false)
const isInitialLoading = ref(true)
const newsLoading = ref(true)
const newsError = ref(null)

const streamUrl = 'https://construction-camera-stream.infodatek.com/readyz'

// View cycling intervals
let viewToggleInterval = null
let newsToggleInterval = null

const formattedTime = computed(() => {
  return dayjs(dateTime.value).format('HH:mm')
})

const formattedDate = computed(() => {
  return dayjs(dateTime.value).format('dddd D MMMM')
})

async function checkStreamAvailability() {
  try {
    const response = await fetch(streamUrl, { method: 'GET' })
    streamAvailable.value = response.ok
    return response.ok
  } catch (error) {
    console.log('Stream not available:', error)
    streamAvailable.value = false
    return false
  }
}

async function getNewsPosts() {
  try {
    newsLoading.value = true
    newsError.value = null
    const response = await NewsService.index()
    if (response.data && response.data.articles) {
      newsPosts.value = Object.values(response.data.articles)
        .filter((post, index, self) =>
          self.findIndex((t) => t.title === post.title) === index
        )
        .filter(post => post.image && post.title && post.description)
    }
  } catch (error) {
    console.error('Error fetching news:', error)
    newsError.value = 'Could not load news'
  } finally {
    newsLoading.value = false
  }
}

function updateDateTime() {
  dateTime.value = new Date()
}

function toggleExpandedNews() {
  showExpandedNews.value = !showExpandedNews.value
}

async function initializeApp() {
  try {
    await Promise.all([
      getNewsPosts(),
      checkStreamAvailability()
    ])
  } finally {
    isInitialLoading.value = false
  }
}

// Constants for timing
const IMAGE_DISPLAY_TIME = 120000  // 2 minutes
const NEWS_TOGGLE_TIME = 600000    // 2 minutes

// Handle stream availability status
const handleStreamStatus = (isAvailable) => {
  streamAvailable.value = isAvailable
  if (!isAvailable && showLatestImage.value) {
    showLatestImage.value = false
  }
}

const startViewCycle = () => {
  // Clear any existing intervals
  if (viewToggleInterval) {
    clearInterval(viewToggleInterval)
  }

  // Start with image view if stream is available
  showLatestImage.value = streamAvailable.value

  // Set up the cycling interval
  viewToggleInterval = setInterval(async () => {
    if (showLatestImage.value) {
      // If showing image, switch to dashboard
      showLatestImage.value = false
    } else if (streamAvailable.value) {
      // If showing dashboard and stream is available, switch to image
      showLatestImage.value = true
    }
  }, IMAGE_DISPLAY_TIME)
}

const startNewsToggle = () => {
  if (newsToggleInterval) {
    clearInterval(newsToggleInterval)
  }

  newsToggleInterval = setInterval(() => {
    if (!showLatestImage.value) {
      toggleExpandedNews()
    }
  }, NEWS_TOGGLE_TIME)
}

onMounted(async () => {
  await initializeApp()

  // Set up regular intervals
  setInterval(getNewsPosts, 7200000) // Every 2 hours
  setInterval(updateDateTime, 1000) // Every second

  // Start the view and news cycles
  startViewCycle()
  startNewsToggle()
})

onUnmounted(() => {
  // Clean up intervals
  if (viewToggleInterval) clearInterval(viewToggleInterval)
  if (newsToggleInterval) clearInterval(newsToggleInterval)
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
