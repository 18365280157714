import { createRouter, createWebHistory } from 'vue-router'
import Default from '../components/Default.vue'

const routes = [
  {
    path: '/',
    name: 'default',
    component: Default
  }
]

export default createRouter({
  history: createWebHistory(),
  routes
})
