<template>
  <div class="h-full p-6">
    <div class="mb-4">
      <h3 class="text-3xl font-bold">Nieuws</h3>
    </div>

    <Transition name="fade" mode="out-in">
      <div
        v-if="currentArticle"
        :key="currentArticle.title"
        class="h-[calc(100%-4rem)] flex flex-col"
      >
        <!-- Left side: Current Article -->
        <div class="flex flex-col space-y-4 min-h-0">
          <!-- Source and Time -->
          <div class="flex items-center justify-between text-xl text-white/80 shrink-0">
            <span>{{ currentArticle.source.name }}</span>
            <span>{{ formatDate(currentArticle.publishedAt) }}</span>
          </div>

          <!-- Title -->
          <h4 class="text-3xl font-semibold line-clamp-2 shrink-0">
            {{ currentArticle.title }}
          </h4>

          <!-- Image Section - Made responsive with min-h-0 and shrink -->
          <div class="relative min-h-0 flex-1 rounded-xl overflow-hidden">
            <img
              :src="currentArticle.image"
              :alt="currentArticle.title"
              class="w-full h-full object-cover"
            />
          </div>

          <!-- Description -->
          <p class="text-xl text-gray-300 line-clamp-2 shrink-0">{{ currentArticle.description }}</p>
        </div>

        <!-- Navigation Dots -->
        <div class="flex justify-center gap-3 py-2 mt-auto shrink-0">
          <button
            v-for="(_, index) in newsPosts"
            :key="index"
            @click="currentIndex = index"
            class="w-3 h-3 rounded-full transition-colors"
            :class="index === currentIndex ? 'bg-white' : 'bg-gray-600'"
          />
        </div>
      </div>
      <div v-else class="h-full flex items-center justify-center">
        <p class="text-2xl text-gray-400">No news available</p>
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watchEffect } from 'vue'
import dayjs from '@/plugins/dayjs'

const props = defineProps({
  newsPosts: {
    type: Array,
    required: true
  },
  compactMode: {
    type: Boolean,
    default: false
  }
})

const currentIndex = ref(0)
let intervalId = null

// Reset currentIndex when posts change
watchEffect(() => {
  if (props.newsPosts.length && currentIndex.value >= props.newsPosts.length) {
    currentIndex.value = 0
  }
})

const currentArticle = computed(() => {
  return props.newsPosts[currentIndex.value]
})

const nextThreeArticles = computed(() => {
  const nextArticles = []
  for (let i = 1; i <= 3; i++) {
    const index = (currentIndex.value + i) % props.newsPosts.length
    nextArticles.push(props.newsPosts[index])
  }
  return nextArticles
})

const formatDate = (date) => {
  return dayjs(date).format('HH:mm')
}

const startAutoPlay = () => {
  stopAutoPlay()
  if (props.newsPosts.length > 1) {
    intervalId = setInterval(() => {
      currentIndex.value = (currentIndex.value + 1) % props.newsPosts.length
    }, 16000)
  }
}

const stopAutoPlay = () => {
  if (intervalId) {
    clearInterval(intervalId)
    intervalId = null
  }
}

onMounted(() => {
  startAutoPlay()
})

onBeforeUnmount(() => {
  stopAutoPlay()
})
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
