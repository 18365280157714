import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import dayjs from './plugins/dayjs'

const app = createApp(App)

// Add dayjs as a global property
app.config.globalProperties.$dayjs = dayjs

app.use(router)
app.use(store)
app.mount('#app')
