<template>
  <div class="h-full flex flex-col">
    <div v-if="loading" class="h-full flex items-center justify-center">
      <p class="text-xl text-gray-400">Loading weather data...</p>
    </div>
    <div v-else-if="error" class="h-full flex items-center justify-center">
      <p class="text-xl text-red-400">{{ error }}</p>
    </div>
    <div v-else-if="weather" class="h-full flex flex-col space-y-6">
      <!-- Current Weather -->
      <div class="flex items-center justify-between">
        <div class="space-y-2">
          <p class="text-6xl font-bold">{{ Math.round(weather.current.temp) }}°C</p>
          <p class="text-2xl text-gray-300 capitalize">
            {{ weather.current.weather[0].description }}
          </p>
        </div>
        <img
          :src="`https://openweathermap.org/img/wn/${weather.current.weather[0].icon}@2x.png`"
          :alt="weather.current.weather[0].description"
          class="w-20 h-20"
        />
      </div>

      <!-- Weather Details -->
      <div class="grid grid-cols-2 gap-4">
        <div>
          <p class="text-lg text-gray-400">Voelt als</p>
          <p class="text-xl font-semibold">{{ Math.round(weather.current.feels_like) }}°C</p>
        </div>
        <div>
          <p class="text-lg text-gray-400">UV Index</p>
          <p class="text-xl font-semibold">{{ Math.round(weather.current.uvi) }}</p>
        </div>
        <div>
          <p class="text-lg text-gray-400">Vochtigheid</p>
          <p class="text-xl font-semibold">{{ weather.current.humidity }}%</p>
        </div>
        <div>
          <p class="text-lg text-gray-400">Wind</p>
          <p class="text-xl font-semibold">{{ Math.round(weather.current.wind_speed * 3.6) }} km/h</p>
        </div>
      </div>

      <!-- Next Hours Forecast -->
      <div class="flex-1 flex flex-col">
        <p class="text-lg text-gray-400 mb-3">Komende uren</p>
        <div class="flex-1 flex flex-col justify-between">
          <div v-for="hour in nextThreeHours" :key="hour.dt"
               class="flex items-center justify-between py-2">
            <span class="text-xl font-semibold">{{ formatHour(hour.dt) }}</span>
            <div class="flex items-center gap-2">
              <img
                :src="`https://openweathermap.org/img/wn/${hour.weather[0].icon}.png`"
                :alt="hour.weather[0].description"
                class="w-8 h-8"
              />
              <span class="text-xl font-semibold">{{ Math.round(hour.temp) }}°</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import dayjs from '../plugins/dayjs.js'
import { getWeather } from "../services/weatherService.js"

const weather = ref(null)
const loading = ref(true)
const error = ref(null)

const nextThreeHours = computed(() => {
  if (!weather.value?.hourly) return []
  return weather.value.hourly.slice(1, 4)
})

const formatHour = (timestamp) => {
  return dayjs(timestamp * 1000).format('HH:mm')
}

async function fetchWeather() {
  try {
    loading.value = true
    error.value = null
    weather.value = await getWeather()
  } catch (err) {
    error.value = 'Kon het weer niet laden'
    console.error('Weather error:', err)
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  await fetchWeather()
})
</script>
