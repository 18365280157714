import dayjs from 'dayjs'
import 'dayjs/locale/nl' // Import Dutch locale
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

// Extend dayjs with plugins
dayjs.extend(LocalizedFormat)

// Set default locale to Dutch
dayjs.locale('nl')

export default dayjs
