<template>
  <div ref="containerRef" class="h-full flex flex-col p-6">
    <div class="shrink-0">
      <h3 class="text-2xl font-bold">Nieuws</h3>
    </div>

    <div class="mt-4 flex-1 min-h-0 overflow-hidden">
      <div class="space-y-3">
        <div
          v-for="(article, index) in displayedArticles"
          :key="article.title"
          class="flex items-center bg-primary/50 rounded-lg p-3 h-[68px] shrink-0"
        >
          <span class="text-xl font-bold text-gray-400 mr-4">{{ index + 1 }}</span>
          <div class="flex-1">
            <h4 class="text-lg font-semibold line-clamp-1">{{ article.title }}</h4>
            <div class="flex items-center space-x-4 text-sm text-gray-400 mt-1">
              <span>{{ article.source.name }}</span>
              <span>{{ formatDate(article.publishedAt) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import dayjs from '../plugins/dayjs'

const props = defineProps({
  newsPosts: {
    type: Array,
    required: true
  }
})

const containerRef = ref(null)
const articleCount = ref(4)

const ARTICLE_HEIGHT = 68 // Fixed article height
const ARTICLE_GAP = 12 // Space between articles
const HEADER_HEIGHT = 48 // Header height + margin

const updateArticleCount = () => {
  if (!containerRef.value) return

  const availableHeight = containerRef.value.clientHeight - HEADER_HEIGHT - 48 // Account for padding
  const heightPerArticle = ARTICLE_HEIGHT + ARTICLE_GAP
  const possibleArticles = Math.floor(availableHeight / heightPerArticle)

  // Set article count between 4 and maximum that fits
  articleCount.value = Math.max(4, Math.min(8, possibleArticles))
}

const displayedArticles = computed(() => {
  return props.newsPosts.slice(0, articleCount.value)
})

const formatDate = (date) => {
  return dayjs(date).format('HH:mm')
}

onMounted(() => {
  const resizeObserver = new ResizeObserver(() => {
    updateArticleCount()
  })
  resizeObserver.observe(containerRef.value)

  // Initial calculation
  updateArticleCount()
})
</script>

<style scoped>
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
