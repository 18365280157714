<template>
  <div class="p-6 h-full">
    <div class="mb-4">
      <h3 class="text-2xl font-bold text-left">Infodatek HQ 2.0</h3>
    </div>
    <div class="relative h-[calc(100%-4rem)] w-full">
      <!-- Show loading state -->
      <div v-if="isLoading" class="absolute inset-0 flex items-center justify-center bg-gray-900/50 rounded-lg">
        <p class="text-xl text-gray-300">Loading camera feed...</p>
      </div>

      <!-- Show error state with details -->
      <div v-else-if="hasError" class="absolute inset-0 flex flex-col gap-2 items-center justify-center bg-gray-900/50 rounded-lg">
        <p class="text-xl text-red-400">Camera feed niet beschikbaar</p>
        <p v-if="errorDetails" class="text-sm text-red-300">{{ errorDetails }}</p>
      </div>

      <!-- Show stream when available -->
      <img
        v-else
        ref="streamRef"
        class="w-full h-full object-cover rounded-lg"
        src="https://construction-camera-stream.infodatek.com/stream.mjpg"
        alt="Construction Camera"
        @error="handleStreamError"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const isLoading = ref(true)
const hasError = ref(false)
const errorDetails = ref('')
const streamRef = ref(null)

const handleStreamError = () => {
  hasError.value = true
  isLoading.value = false
  errorDetails.value = 'Stream connection failed'
  emit('streamError')
}

const emit = defineEmits(['streamError', 'streamReady'])

// Function to check if stream is available using the readyz endpoint
const checkStreamAvailability = async () => {
  try {
    isLoading.value = true
    hasError.value = false
    errorDetails.value = ''

    const response = await fetch('https://construction-camera-stream.infodatek.com/readyz')
    const data = await response.json()

    if (response.status !== 200) {
      throw new Error(data.error || 'Stream service not ready')
    }

    if (data.status !== 'ready') {
      // If not ready, show specific issues from the checks
      const issues = Object.entries(data.checks)
        .filter(([_, status]) => status !== 'operational' && status !== 'writable')
        .map(([component, status]) => `${component}: ${status}`)
        .join(', ')

      throw new Error(`System not ready: ${issues}`)
    }

    isLoading.value = false
    emit('streamReady')
  } catch (error) {
    console.error('Stream availability check failed:', error)
    hasError.value = true
    errorDetails.value = error.message
    emit('streamError')
  } finally {
    isLoading.value = false
  }
}

// Polling function to periodically check stream availability
const startStreamPolling = () => {
  const pollInterval = 30000 // Poll every 30 seconds
  checkStreamAvailability()

  setInterval(() => {
    if (hasError.value) {
      checkStreamAvailability()
    }
  }, pollInterval)
}

onMounted(() => {
  startStreamPolling()
})
</script>
