import Api from '@/services/Api'

export default {
  index(credentials) {
    return Api().get('', {
      params: {
        country: 'nl',
        country: 'nl',
        token: '4a72f3edea315f4e88e18b731687797d'
      }
    })
  }
}
